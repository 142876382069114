import { useSetAtom } from 'jotai';
import { incompleteUserDataAtom } from 'components/auth/store';
import { useRouter } from 'next/router';
import useAuthComplete from 'components/auth/hooks/useAuthComplete';
import { useUserDataMutation } from 'components/auth/hooks/authFlowHooks';
import { LoginType } from 'auth/constants';
import { useUserDetails } from 'auth/mutations/userHooks';
import { accountCompletedAtom } from 'components/checkout/buoy/jotaiStore';
import boatsetterPaths from 'utils/boatsetterPaths';

export default function useIncompleteUserProcessing() {
  const router = useRouter();

  const { handleUserAfterAuthActions, runAnalytics } = useAuthComplete();

  const userDataMutation = useUserDataMutation();

  const { userDetails, isLoading: isUserDetailsLoading } = useUserDetails();

  const setIncompleteUserData = useSetAtom(incompleteUserDataAtom);
  const setAccountCompleted = useSetAtom(accountCompletedAtom);

  const handlePossibleIncompleteAccountInApp = async (redirectTo: string) => {
    // Wait until user details have finished loading before proceeding
    if (isUserDetailsLoading) {
      return false;
    }

    if (userDetails?.account_completed) {
      setAccountCompleted(true);
      return true;
    }

    setIncompleteUserData({
      phone: userDetails.phone,
      last_name: userDetails.last_name,
      accepted_terms_at: userDetails.accepted_terms_at,
      tcpa_accepted_at: userDetails.tcpa_accepted_at,
      first_name: userDetails.first_name,
      account_completed: userDetails.account_completed,
      birthdate: userDetails.birthdate,
      email: userDetails.email,
      phone_verified: userDetails.phone_verified,
    });

    router.push({
      pathname: `${boatsetterPaths.signUp}/complete_account`,
      query: {
        redirect_to: redirectTo,
      },
    });
    setAccountCompleted(false);
    return false;
  };

  // handles initial login/signup for tracking etc
  const handlePossiblyIncompleteAccountInAuth = async (loginType: LoginType, isFirstTimeSocial: boolean) => {
    const userData = await userDataMutation.mutateAsync();

    runAnalytics(userData, loginType);

    setIncompleteUserData({
      phone: userData.phone,
      last_name: userData.last_name,
      first_name: userData.first_name,
      account_completed: userData.account_completed,
      birthdate: userData.birthdate,
      email: userData.email,
      tcpa_accepted_at: userData.tcpa_accepted_at,
      accepted_terms_at: userData.accepted_terms_at,
      phone_verified: userData.phone_verified,
    });

    if (userData.account_completed) {
      await handleUserAfterAuthActions();
      return;
    }

    await router.push({
      pathname: `${boatsetterPaths.signUp}/complete_account`,
      query: {
        ...router.query,
        isFirstTimeSocial,
      },
    });
  };

  return {
    handlePossiblyIncompleteAccountInAuth,
    handlePossibleIncompleteAccountInApp,
  };
}
