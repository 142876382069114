import styled from 'styled-components';
import useRudderStackTrack from 'hooks/useRudderStackTrack';

const PolicyModal = ({ body, link, linkText = 'Learn more', isList, trackID }) => {
  const rudderTrack = useRudderStackTrack();
  const trackBeforeNav = (eventName: string) => {
    rudderTrack(eventName, {});
    return true;
  };

  return (
    <Wrapper data-testid="checkout-additional-links-policy-modal">
      {isList ? (
        <List>
          {body.map((item) => (
            <ListItem>{item}</ListItem>
          ))}
        </List>
      ) : (
        <>
          {body.map((item) => (
            <p>{item}</p>
          ))}
        </>
      )}
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        onClick={() => trackBeforeNav(`click_checkout_policy_modal_${trackID}`)}
      >
        {linkText}
      </a>
    </Wrapper>
  );
};

export default PolicyModal;

const Wrapper = styled.div`
  padding: 0 1.5rem 1.5rem;
`;

const List = styled.ul`
  list-style-type: disc;
  list-style-position: outside;
  margin-left: 1.75rem;
  margin-bottom: 1.5rem;
`;

const ListItem = styled.li`
  margin-bottom: 0.75rem;
`;
