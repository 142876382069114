import PolicyModal from './PolicyModal';

const modalText = {
  renter: {
    body: [
      'As the renter, you are responsible for the cost of fuel used during your booking.',
      'The owner may require you refill your boat prior to docking, request payment in person or submit a fuel reimbursement form after the booking for fuel used.',
    ],
  },
  owner: {
    body: [
      'The owner is responsible for the cost of fuel used during your booking and will handle refueling the boat themselves.',
    ],
  },
};
const link = 'https://support.boatsetter.com/hc/en-us/articles/360034663992-Paying-for-fuel';
const linkText = 'Read support article';

const FuelPolicyModal = ({ whoPays }) => {
  const content = modalText[whoPays];
  const trackID = 'fuel';

  return <PolicyModal body={content.body} link={link} linkText={linkText} trackID={trackID} />;
};

export default FuelPolicyModal;
