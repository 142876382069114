import Bugsnag from '@bugsnag/js';
import { AfterAuthActionType, LoginType } from 'auth/constants';
import { afterAuthActionsAtom, clearAfterAuthActionsAtom, failedLoginAtom } from 'auth/userStore';
import dayjs from 'dayjs';
import { getAsString } from 'helpers';
import { getDefaultAnalyticsTraits } from 'helpers/analytics';
import useRudderStackTrack from 'hooks/useRudderStackTrack';
import { useAtomValue, useSetAtom } from 'jotai';
import Cookies from 'js-cookie';
import LogRocket from 'logrocket';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { User } from 'swagger/models';
import { trackEvent } from 'utils/tracking/trackEvent';
import boatsetterPaths from 'utils/boatsetterPaths';

export default function useAuthComplete() {
  const router = useRouter();
  const afterAuthActions = useAtomValue(afterAuthActionsAtom);
  const clearAfterAuthActions = useSetAtom(clearAfterAuthActionsAtom);
  const setLoginFailed = useSetAtom(failedLoginAtom);
  const queryClient = useQueryClient();
  const rudderTrack = useRudderStackTrack();

  const runAnalytics = (payload: User, loginType: LoginType) => {
    const currentUserId = Cookies.get('user_public_id');
    if (!!currentUserId && currentUserId !== payload.id) {
      // schedule it for 1s to call it after identify event, not ideal but easiest solution
      setTimeout(() => {
        window.rudderanalytics?.alias(payload.id, currentUserId || window.rudderanalytics?.getAnonymousId());
      }, 1000);
    }
    Cookies.set('user_public_id', payload.id);

    const isSignup = dayjs().diff(payload.created_at, 'second') < 120;

    window.rudderanalytics?.identify(payload.id, {
      ...getDefaultAnalyticsTraits(),
      user_public_id: payload.id,
      is_signup: isSignup,
      login_type: loginType,
    });

    Bugsnag.setUser(payload.id, payload.email);
    LogRocket.identify(payload.id);
    trackEvent({
      userId: payload.id,
    });
    rudderTrack('authComplete', { userId: payload.id, loginType });
  };

  const handleUserAfterAuthActions = useCallback(async () => {
    setLoginFailed(false);

    const createBoatActions = afterAuthActions.filter((e) => e.type === AfterAuthActionType.CREATE_BOAT);

    afterAuthActions
      .filter((e) => e.type === AfterAuthActionType.ADD_FAVORITE_BOAT)
      .forEach((e) => {
        e.action();
      });
    await queryClient.invalidateQueries();
    if (createBoatActions.length > 0) {
      const action = createBoatActions[0];
      try {
        const boatData = await action.action();

        if (!boatData) return;

        router.push({
          pathname: `${boatsetterPaths.listBoat}/${boatData.data.id}/details`,
        });
        return;
      } catch (e) {
        Bugsnag.notify(e);
        router.push({
          pathname: '/',
        });
        console.error(e);
        return;
      }
    }

    router.push(getAsString(router.query?.redirect_to) || '/');

    clearAfterAuthActions(AfterAuthActionType.ADD_FAVORITE_BOAT);
    clearAfterAuthActions(AfterAuthActionType.CREATE_BOAT);
  }, [router, afterAuthActions, clearAfterAuthActions, setLoginFailed, queryClient]);

  return {
    handleUserAfterAuthActions,
    runAnalytics,
  };
}
