import { useMemo } from 'react';
import { Text } from 'components/primitives/typography';
import styled from 'styled-components';
import { dollarsNoCentsWithSymbol } from 'helpers/currency';
import useRudderStackTrack from 'hooks/useRudderStackTrack';
import useModal from '../modal/useModal';
import { getCharterTypeUrl, GetCharterTypeUrlType } from '../../forms/bookingWidget/utils';
import CancellationPolicyModal from '../policy-modals/CancellationPolicyModal';
import SecurityDepositModal from '../policy-modals/SecurityDepositModal';
import FuelPolicyModal from '../policy-modals/FuelPolicyModal';

type SpecsType = {
  cancellationPolicy: string;
  fuelPolicy: string;
  securityDepositCents: number;
  charterTypeParams: GetCharterTypeUrlType;
};

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const getCharterTypeObject = (params: GetCharterTypeUrlType) => {
  const ownerIsCaptain =
    params?.selectedCaptain?.id && params?.selectedCaptain?.id === params?.selectedCaptain?.boatOwnerId;

  const isTimeCharter = ownerIsCaptain || params.selectedPackage.charter_agreement_type === 'time_charter';

  return {
    label: 'Charter type:',
    link: getCharterTypeUrl(params),
    linkText: isTimeCharter ? 'Time Charter' : 'Bareboat',
    dataTestID: 'charter_type',
    trackEvent: (track) => {
      track('click_checkout_charter_type');
      return true;
    },
  };
};

const BookingSpecs = ({
  specsDataObj,
  testIdPrefix,
  className,
}: {
  specsDataObj: SpecsType;
  testIdPrefix: string;
  className?: string;
}) => {
  const charterTypeObject = useMemo(
    () => getCharterTypeObject(specsDataObj.charterTypeParams),
    [specsDataObj.charterTypeParams]
  );
  const rudderTrack = useRudderStackTrack();

  const { modal: cancellationPolicyModal, openModal: openCancellationPolicyModal } = useModal({
    modalContent: <CancellationPolicyModal policy={specsDataObj.cancellationPolicy} />,
    title: `Cancellation policy: ${capitalize(specsDataObj.cancellationPolicy)}`,
    dataName: 'cancellation-policy',
  });
  const { modal: securityDepositModal, openModal: openSecurityDepositModal } = useModal({
    modalContent: <SecurityDepositModal />,
    title: 'Security Deposit',
    dataName: 'security-deposit',
  });
  const whoPays = specsDataObj.fuelPolicy === 'renter_pays' ? 'renter' : 'owner';
  const { modal: fuelPolicyModal, openModal: openFuelPolicyModal } = useModal({
    modalContent: <FuelPolicyModal whoPays={whoPays} />,
    title: `Fuel policy: ${whoPays === 'renter' ? 'Renter pays' : 'Owner pays'}`,
    dataName: 'fuel-policy',
  });
  return (
    <>
      <List className={className}>
        <li data-testid={`${testIdPrefix}-fuel_policy`}>
          {specsDataObj.fuelPolicy === 'renter_pays' || specsDataObj.fuelPolicy === 'Renter pays'
            ? 'Fuel is not included.'
            : 'Fuel is included.'}{' '}
          <PolicyModalTrigger type="button" onClick={openFuelPolicyModal}>
            Learn more
          </PolicyModalTrigger>
        </li>
        <li data-testid={`${testIdPrefix}-security_deposit`}>
          Security deposit:{' '}
          {specsDataObj.securityDepositCents !== 0
            ? dollarsNoCentsWithSymbol(specsDataObj.securityDepositCents)
            : dollarsNoCentsWithSymbol(50000)}
          .{' '}
          <PolicyModalTrigger type="button" onClick={openSecurityDepositModal}>
            Learn more
          </PolicyModalTrigger>
        </li>
        <li data-testid={`${testIdPrefix}-cancellation_policy`}>
          Cancellation policy:{' '}
          <PolicyModalTrigger type="button" onClick={openCancellationPolicyModal}>
            {specsDataObj.cancellationPolicy}
          </PolicyModalTrigger>
        </li>
        <li data-testid={`${testIdPrefix}-${charterTypeObject.dataTestID}`}>
          <span>{charterTypeObject.label}</span>
          &nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={charterTypeObject.link}
            onClick={() => {
              charterTypeObject.trackEvent(rudderTrack);
            }}
          >
            <Text color="#0751C2" as="span">
              {charterTypeObject.linkText}
            </Text>
          </a>
        </li>
      </List>
      {cancellationPolicyModal}
      {securityDepositModal}
      {fuelPolicyModal}
    </>
  );
};

export default BookingSpecs;

const List = styled.ul`
  list-style: disc;
  padding-left: 1em;
  color: #5e696a;

  li {
    padding: 4px 0;
    font-size: 14px;
    font-weight: 400;
  }
`;

const PolicyModalTrigger = styled.button`
  text-transform: capitalize;
  color: #0751c2;
`;
