import { useState } from 'react';
import Image from 'next/image';
import LoadingSpinner from 'components/loading/LoadingSpinner';
import styled from 'styled-components';
import { FlexCenterBoth } from 'components/primitives/flex';
import { rgbDataURL } from 'utils';
import { cloudFlareLoader } from '../../../utils/images';

export default function NextImageWithLoading(props) {
  const { loader, alt } = props;

  const [isLoading, setIsLoading] = useState(true);
  return (
    <>
      <SpinnerContainer>{isLoading && <LoadingSpinner bg={false} />}</SpinnerContainer>
      <StyledImage
        alt={alt || ''}
        placeholder="blur"
        blurDataURL={rgbDataURL(100, 100, 100)}
        loader={loader || cloudFlareLoader}
        {...props}
        onLoad={() => setIsLoading(false)}
        onError={() => setIsLoading(false)}
      />
    </>
  );
}

const SpinnerContainer = styled(FlexCenterBoth)`
  height: 100%;
  width: 100%;
`;

const StyledImage = styled(Image)`
  filter: initial !important;
`;
