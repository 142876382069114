const CloseIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.4568 0.67899C10.6954 0.440337 11.0824 0.440337 11.321 0.67899C11.5597 0.917644 11.5597 1.30458 11.321 1.54323L1.54323 11.321C1.30458 11.5597 0.917644 11.5597 0.67899 11.321C0.440337 11.0824 0.440337 10.6954 0.67899 10.4568L10.4568 0.67899Z"
      fill="#5E696A"
      stroke="#5E696A"
      strokeWidth="0.4"
    />
    <path
      d="M11.321 10.4568C11.5597 10.6954 11.5597 11.0824 11.321 11.321C11.0824 11.5597 10.6954 11.5597 10.4568 11.321L0.67899 1.54323C0.440337 1.30458 0.440337 0.917644 0.67899 0.67899C0.917644 0.440337 1.30458 0.440337 1.54323 0.67899L11.321 10.4568Z"
      fill="#5E696A"
      stroke="#5E696A"
      strokeWidth="0.4"
    />
  </svg>
);

export default CloseIcon;
