import PolicyModal from './PolicyModal';

const body = [
  'For all approved bookings, a security deposit is held (not a charge) on your card to cover any incidental costs, damages, or lost items. Security deposits are held 48 hours prior to the booking start time for the value shown on the checkout screen and released 48 hours after the booking end time, if there are no claims filed.',
  'If your booking is approved within 48 hours of the start time, the security deposit will be held in addition to the separate charge for the total booking payment.',
];
const linkText = 'Read support article';
const link = 'https://support.boatsetter.com/hc/en-us/articles/205348914-Security-deposit';
const trackID = 'security_deposit';

const SecurityDepositModal = () => <PolicyModal body={body} linkText={linkText} link={link} trackID={trackID} />;

export default SecurityDepositModal;
