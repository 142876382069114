import { useEffect, useRef, useState } from 'react';
import CloseIcon from 'components/icons/CloseIcon';
import styled from 'styled-components';
import { mediaMMdMin } from 'helpers/breakpoints';
import useRudderStackTrack from 'hooks/useRudderStackTrack';

const Modal = ({ children, title = false, closeModal, dataName, hasFade }) => {
  const scrollRef = useRef(null);
  const [isAtTop, setIsAtTop] = useState(true);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [shouldShowFade, setShouldShowFade] = useState(hasFade);
  const rudderTrack = useRudderStackTrack();

  useEffect(() => {
    const hasScroll = scrollRef.current.scrollHeight > scrollRef.current.clientHeight;
    if (!hasFade && shouldShowFade) {
      setShouldShowFade(false);
    } else if (hasFade && !isAtBottom && hasScroll) {
      setShouldShowFade(true);
    } else if (hasFade && isAtBottom) {
      rudderTrack('scroll_checkout_boat_modal_bottom', {});
      setShouldShowFade(false);
    }
  }, [isAtBottom]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = scrollRef.current.scrollTop;
      const scrollBottomPostion = Math.round(scrollPosition + scrollRef.current.clientHeight + 16);
      const windowScrollPosition = scrollRef.current.scrollHeight;
      if (scrollPosition < 2) {
        setIsAtTop(true);
      } else if (scrollPosition >= 2) {
        setIsAtTop(false);
      }

      if (windowScrollPosition <= scrollBottomPostion) {
        setIsAtBottom(true);
      } else if (windowScrollPosition > scrollBottomPostion) {
        setIsAtBottom(false);
      }
    };

    if (scrollRef.current) {
      scrollRef.current.addEventListener('scroll', handleScroll);
    }
    const hasScroll = scrollRef.current.scrollHeight > scrollRef.current.clientHeight;
    if (!hasScroll) setShouldShowFade(false);

    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <ModalBackground>
      <ModalWrapper>
        <ModalHeader isAtTop={isAtTop}>
          {title && <h2>{title}</h2>}
          <CloseButton
            data-testid={`close-modal-button${dataName && `-${dataName}`}`}
            type="button"
            onClick={closeModal}
          >
            <CloseIcon />
          </CloseButton>
        </ModalHeader>
        <ModalContent ref={scrollRef} shouldShowFade={shouldShowFade}>
          {children}
        </ModalContent>
      </ModalWrapper>
    </ModalBackground>
  );
};

const ModalBackground = styled.div`
  background-color: rgba(10, 37, 47, 0.6);
  height: 100vh;
  width: 100vw;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: end;
  ${mediaMMdMin} {
    align-items: center;
  }
`;

const ModalWrapper = styled.div`
  background-color: white;
  width: 720px;
  max-width: 100%;
  max-height: 90%;
  border-radius: 1.25rem 1.25rem 0 0;
  display: flex;
  flex-direction: column;
  ${mediaMMdMin} {
    max-width: 90%;
    border-radius: 1.25rem;
  }
`;

const CloseButton = styled.button`
  grid-column: 2;
`;

const ModalHeader = styled.div<{ isAtTop: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  box-shadow: ${(props) => (props.isAtTop ? 'none' : '0px 7px 8px 0px #00000026')};
  padding: 1rem 1.5rem 0.5rem 1.5rem;

  h2 {
    font-size: 24px;
    font-weight: 700;
  }
`;

const ModalContent = styled.div<{ shouldShowFade: boolean }>`
  overflow: scroll;
  height: calc(100% - 1.5rem - 2rem - 6px);
  mask-image: ${(props) => (props.shouldShowFade ? 'linear-gradient(to bottom, black 80%, transparent 100%)' : 'none')};
`;

export default Modal;
