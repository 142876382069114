import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { apiParamsHourNameMapReverse } from 'components/forms/bookingWidget/utils';
import { Booking, TripCalculator, BookingPrerequisiteTypeEnum } from 'swagger/models';
import { CheckoutRequestGuardPayloadType } from 'components/booking/queryHooks';
import { TripCalculatorPayload } from 'hooks/queries';
import { NextParsedUrlQuery } from 'next/dist/server/request-meta';
import moment, { Moment } from 'moment';

dayjs.extend(isBetween);

export enum CheckoutDuration {
  fullDay = 'full_day',
  halfDay = 'half_day',
  twoHours = 'two_hours',
  threeHours = 'three_hours',
  sixHours = 'six_hours',
  multiDay = 'multi_day',
}

export type IntroductionMessageData = {
  ownerFirstName: string;
  startTime: string;
  finishDate?: string;
  startDate: string;
  duration: CheckoutDuration;
};
export const getDefaultIntroductionMessage = (isInstantBook: boolean, data: IntroductionMessageData) => {
  const { startTime, duration, ownerFirstName, finishDate, startDate } = data;
  const formattedDate = dayjs(startDate).format('MMM DD');
  const formattedStartTime = dayjs(startTime).format('h:mm A');

  const namedDuration =
    duration === CheckoutDuration.multiDay
      ? transformDurationNames(duration, startDate, finishDate)
      : transformDurationNames(duration);

  if (isInstantBook) {
    return `Hi ${ownerFirstName}, I booked your boat on ${formattedDate} at ${formattedStartTime} for ${namedDuration}.`;
  }

  return `Hi ${ownerFirstName}, I am requesting to book your boat on ${formattedDate} at ${formattedStartTime} for ${namedDuration}.`;
};

type TransformDurationNamesType = {
  (duration: Exclude<CheckoutDuration, CheckoutDuration.multiDay>): string;
  (duration: CheckoutDuration.multiDay, startTime: string, finishTime: string): string;
};

export const transformDurationNames: TransformDurationNamesType = (
  duration: CheckoutDuration,
  startTime?: string,
  finishTime?: string
): string => {
  if (duration === CheckoutDuration.multiDay) {
    return `${dayjs(finishTime).diff(dayjs(startTime), 'day')} days`;
  }
  return `${apiParamsHourNameMapReverse[duration]} hours`;
};

const selectPackage = (package_type, packages) =>
  packages && packages.find((boatPackage) => package_type === boatPackage.package_type);

export const getParamsForRequestGuardQuery = (
  booking: Booking,
  purchasingInsurance?: boolean,
  insuranceRenterPolicyId?: string
): CheckoutRequestGuardPayloadType => {
  const selectedPackage = selectPackage(booking?.package_type, booking?.boat?.packages) || booking.package;
  return {
    booking_id: booking.id,
    package_id: selectedPackage.id,
    trip_start: dayjs(booking.trip_start).format('YYYY-MM-DD'),
    trip_time: dayjs(booking.trip_start).format('HH:mm'),
    trip_finish: dayjs(booking.trip_finish).format('YYYY-MM-DD'),
    passengers: booking.passengers,
    duration: booking.trip_type,
    purchasingInsurance,
    ...(insuranceRenterPolicyId ? { insuranceRenterPolicyId } : {}),
  };
};

export const getParamsForRequestGuardQueryWithoutBooking = (
  query: NextParsedUrlQuery,
  purchasingInsurance?: boolean,
  insuranceRenterPolicyId?: string,
  maxPassengers?: number
): CheckoutRequestGuardPayloadType => {
  // make sure we don't exceed the passenger capacity
  const passengers =
    parseFloat(query['booking[passengers]'] as any) > maxPassengers ? maxPassengers : query['booking[passengers]'];

  return {
    package_id: query['booking[package_public_id]'] as string,
    trip_start: query['booking[trip_start]'] as string,
    trip_time: (query['booking[trip_time]'] as string) || '10:00',
    trip_finish: (query['booking[trip_finish]']
      ? query['booking[trip_finish]']
      : query['booking[trip_start]']) as string,
    passengers: passengers as number,
    duration: query['booking[duration]'] as string,
    purchasingInsurance,
    ...(insuranceRenterPolicyId ? { insuranceRenterPolicyId } : {}),
  };
};

export const getTripCalculatorQueryParams = ({
  query,
  promoCode,
  addons,
  captainId = undefined,
  insuranceRenterPolicyId,
  maxPassengers,
}): TripCalculatorPayload => ({
  packageId: query['booking[package_public_id]'],
  tripStart: query['booking[trip_start]'],
  tripTime: query['booking[trip_time]'] || '10:00',
  tripFinish: query['booking[trip_finish]'] ? query['booking[trip_finish]'] : query['booking[trip_start]'],
  passengers: parseFloat(query['booking[passengers]']) > maxPassengers ? maxPassengers : query['booking[passengers]'],
  duration: query['booking[duration]'],
  // TODO @shafikgomez check in which case we need the actual captain public id
  // captainPublicId: query['booking[captain_public_id]'],
  applyBoatingCredits: true,
  addons,
  insuranceRenterPolicyId: query.renterInsurance === 'yes' ? insuranceRenterPolicyId : null,
  ...(promoCode && { couponCode: promoCode }),
  ...(captainId && { captainId }),
});

export type SelectedBookingData = {
  tripStart: Moment;
  tripFinish: Moment;
  passengers: number;
  packageType: string;
  captainId: string;
};

export const selectBookingData = (calculation: TripCalculator | Booking, captainId?: string): SelectedBookingData => ({
  tripStart: moment.parseZone(calculation.trip_start),
  tripFinish: moment.parseZone(calculation.trip_finish),
  passengers: calculation.passengers,
  packageType: calculation.package_type === 'captained' ? 'Captained' : 'No captain',
  // TODO @vstlouis model needs inspecting

  // @ts-ignore
  captainId: 'captain' in calculation ? calculation?.captain?.uuid : captainId,
});

export const removeLocalBookingData = (isInquiry, bookingTrip) => {
  if (localStorage.getItem('insuranceData')) window?.localStorage?.removeItem('insuranceData');
  if (localStorage.getItem('tripDetails')) window?.localStorage?.removeItem('tripDetails');
  const addonKey = `addons_${isInquiry ? bookingTrip?.id : bookingTrip?.package?.id}`;
  if (localStorage.getItem(addonKey)) window?.localStorage?.removeItem(addonKey);
};

export function bookingOverlapsTrip(bookingStart, bookingFinish, tripStart, tripEnd) {
  return (
    dayjs(bookingStart).isBetween(tripStart, tripEnd, null, '[]') ||
    dayjs(bookingFinish).isBetween(tripStart, tripEnd, null, '[]') ||
    dayjs(tripStart).isBetween(bookingStart, bookingFinish, null, '[]') ||
    dayjs(tripEnd).isBetween(bookingStart, bookingFinish, null, '[]')
  );
}

export const getShouldShowBuoyUnderageModal = (prerequisites) => {
  const agePreReq = prerequisites?.filter((x) => x.type === BookingPrerequisiteTypeEnum.age_restricted)[0];
  const isSatisfied = agePreReq ? agePreReq.satisfied : true;

  return isSatisfied;
};
