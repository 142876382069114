/* eslint-disable no-param-reassign */
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import queryString from 'query-string';
import { useUserDetails } from 'auth/mutations/userHooks';
import { MeApi } from 'swagger/apis/me-api';
import OpenApiConfiguration from 'api/OpenApiConfiguration';
import { useMutation } from 'react-query';
import { DomesticV2MeTrackAbandonedSessionPostRequest } from 'swagger/models';

const meApi = new MeApi(OpenApiConfiguration);

const useTrackAbandonUserSessionMutation = () =>
  useMutation((trackingParams: DomesticV2MeTrackAbandonedSessionPostRequest) =>
    meApi.domesticV2MeTrackAbandonedSessionPost(trackingParams)
  );

const useAbandonEffect = (payload) => {
  const { query } = useRouter();
  const trackAbandonUserSessionMutation = useTrackAbandonUserSessionMutation();

  const { isAuthenticated } = useUserDetails();

  useEffect(() => {
    if (!isAuthenticated) return;

    let time = 0;

    const id = setInterval(() => {
      if (time > 1800 || (query.debug && time > 20)) {
        if (query.debug) {
          // eslint-disable-next-line no-console
          console.log('sending abandon data');
        }
        clearInterval(id);

        const enhancedPayload: DomesticV2MeTrackAbandonedSessionPostRequest = payload;
        if (payload.last_searched_pdp_url) {
          enhancedPayload.last_searched_pdp_url = window.location.origin + window.location.pathname;
        }

        if (payload.abandoned_checkout_url) {
          enhancedPayload.abandoned_checkout_url = window.location.href;
        }

        if (payload.conversation_url) {
          enhancedPayload.conversation_url = `${window.location.origin}/a/inbox/${payload.conversation_url}`;
        }

        if (payload.last_searched_plp_url) {
          enhancedPayload.last_searched_plp_url = `${
            window.location.origin + window.location.pathname
          }?${queryString.stringify({
            action: query.action,
            near: query.near,
          })}`;
        }

        trackAbandonUserSessionMutation.mutateAsync(enhancedPayload);
      }
      if (query.debug) {
        // eslint-disable-next-line no-console
        console.log(time);
      }
      time += 1;
    }, 1000);

    const listener = () => {
      time = 0;
    };

    document.getElementById('__next').addEventListener('scroll', listener);
    return () => {
      document.getElementById('__next').removeEventListener('scroll', listener);
      clearInterval(id);
    };
  }, [isAuthenticated, JSON.stringify(payload)]);
};

export default useAbandonEffect;
