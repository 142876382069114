import PolicyModal from './PolicyModal';

const modalText = {
  flexible: {
    title: 'Cancellation policy: Flexible',
    body: [
      'Free cancellations until 24 hours before the booking start date.',
      'Booking charges for cancellations within 24 hours of the booking start date are non-refundable.',
    ],
  },
  moderate: {
    title: 'Cancellation policy: Moderate',
    body: [
      'Free cancellations until 5 days before the booking start date.',
      '50% refund of booking charges for cancellation between 2-5 days before the booking start date.',
      'Booking charges for cancellations within 2 days of the booking start date are non-refundable.',
    ],
  },
  strict: {
    title: 'Cancellation policy: Strict',
    body: [
      'Free cancellations until 30 days before the booking start date.',
      '50% refund of booking charges for cancellation between 14-30 days before the booking start date.',
      'Booking charges for cancellations within 14 days of the booking start date are non-refundable.',
    ],
  },
};

const CancellationPolicyModal = ({ policy }) => {
  const content = modalText[policy];
  const trackID = 'cancellation';

  return <PolicyModal body={content.body} link="/legal/cancellation_policy" isList trackID={trackID} />;
};

export default CancellationPolicyModal;
