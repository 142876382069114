// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Virtual } from 'swiper';
import Image from 'next/image';
import { useRef, useState } from 'react';
import styled from 'styled-components';
import useRudderStackTrack from 'hooks/useRudderStackTrack';
import { cloudFlareLoader } from '../../../utils/images';
import { mediaMMdMax } from '../../../helpers/breakpoints';
import { StyledArrowNextIcon, StyledArrowPrevIcon } from './common';

export const NEXT_SLIDE_NAV_CLASSNAME = 'nextSlide';
export const PREV_SLIDE_NAV_CLASSNAME = 'prevSlide';

const GalleryDetail = ({ photos, close, slide = 0, boatId }) => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const [index, setIndex] = useState(1);

  const rudderTrack = useRudderStackTrack();

  const onChange = (swiper) => {
    setIndex(swiper.activeIndex + 1);
    rudderTrack('scroll_pdp_photo_gallery_detail', {
      index: swiper.activeIndex,
      boat_id: boatId,
    });
  };

  return (
    <BigImage>
      <ImageWrapper>
        <Swiper
          className="galleryDetail"
          slidesPerView={1}
          modules={[Navigation, Virtual]}
          virtual
          initialSlide={slide}
          onSlideChange={onChange}
          navigation={{
            prevEl: `.${PREV_SLIDE_NAV_CLASSNAME}`,
            nextEl: `.${NEXT_SLIDE_NAV_CLASSNAME}`,
          }}
        >
          {photos.map((photo, i) => (
            <SwiperSlide key={photo.standard.url}>
              <CarouselImage>
                <Image
                  alt=""
                  priority={i === 0}
                  key={photo}
                  src={new URL(photo.standard.url)?.pathname.slice(1)}
                  loader={cloudFlareLoader as any}
                  fill
                  sizes="100vw"
                  style={{
                    objectFit: 'contain',
                  }}
                />
              </CarouselImage>
            </SwiperSlide>
          ))}
        </Swiper>
      </ImageWrapper>
      <CloseWrapper onClick={close} data-testid="galleryDetail-closeBtn">
        <Close />
      </CloseWrapper>
      <StyledArrowPrevIcon ref={navigationPrevRef} className={PREV_SLIDE_NAV_CLASSNAME} />
      <StyledArrowNextIcon ref={navigationNextRef} className={NEXT_SLIDE_NAV_CLASSNAME} />
      <CurrentSlide>
        {index} / {photos.length}
      </CurrentSlide>
    </BigImage>
  );
};

const CurrentSlide = styled.div`
  position: absolute;
  bottom: 2%;
  font-size: 20px;
  left: 50%;
  transform: translateX(-50%);
`;

const CloseWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f7f9;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  top: 25px;
  right: 25px;
`;

const Close = () => (
  <svg width="10" height="10" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2559 0.744078C14.9305 0.418641 14.4028 0.418641 14.0774 0.744078L8 6.82149L1.92259 0.744078C1.59715 0.418641 1.06951 0.418641 0.744078 0.744078C0.418641 1.06951 0.418641 1.59715 0.744078 1.92259L6.82149 8L0.744078 14.0774C0.418641 14.4028 0.418641 14.9305 0.744078 15.2559C1.06951 15.5814 1.59715 15.5814 1.92259 15.2559L8 9.17851L14.0774 15.2559C14.4028 15.5814 14.9305 15.5814 15.2559 15.2559C15.5814 14.9305 15.5814 14.4028 15.2559 14.0774L9.17851 8L15.2559 1.92259C15.5814 1.59715 15.5814 1.06951 15.2559 0.744078Z"
      fill="black"
    />
  </svg>
);

const CarouselImage = styled.div`
  height: 100%;
  position: relative;

  img {
    border-radius: 16px;

    ${mediaMMdMax} {
      border-radius: 0;
    }
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BigImage = styled.div`
  position: fixed;
  background: white;
  top: 0px;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 200;

  .swiper-pagination-fraction {
    //bottom: -10px;
    position: absolute;
  }
`;

export default GalleryDetail;
