import { useMutation } from 'react-query';
import { AccountApi } from 'swagger/apis/account-api';
import OpenApiConfiguration, { API_CLIENT_HEADERS } from 'api/OpenApiConfiguration';
import {
  AppleAuthAuthorizationResponse,
  DomesticV2MePhonesVerificationStartPostRequest,
  TokenExchangeFacebookPostRequest,
  User,
} from 'swagger/models';
import axios, { AxiosResponse } from 'axios';
import { useSetAtom } from 'jotai';
import { loginTypeAtom } from 'auth/userStore';
import { LoginType } from 'auth/constants';
import useRudderStackTrack from 'hooks/useRudderStackTrack';

const accountApi = new AccountApi(OpenApiConfiguration);
export function useAppleConnectMutation() {
  return useMutation(() => accountApi.appleAuthConnectGet());
}

export function useAppleExchangeMutation() {
  const setLoginType = useSetAtom(loginTypeAtom);
  const userDataMutation = useUserDataMutation();
  const rudderTrack = useRudderStackTrack();

  return useMutation((body: AppleAuthAuthorizationResponse) => accountApi.appleAuthCallbackPost(body), {
    onSuccess: async () => {
      setLoginType(LoginType.APPLE_LOGIN);
      try {
        const userData = await userDataMutation.mutateAsync();
        rudderTrack('login_signup_success', {
          type: 'apple',
          name: 'login',
          userId: userData?.id,
        });
      } catch (e) {
        console.error(e);
      }
    },
  });
}

export function useUserDataMutation() {
  return useMutation(() =>
    axios
      .get<any, AxiosResponse<User>>('/domestic/v1/me', {
        headers: API_CLIENT_HEADERS,
      })
      .then((e) => e.data)
  );
}

export function useUpdatePhoneNumberV2Mutation() {
  return useMutation((body: DomesticV2MePhonesVerificationStartPostRequest) =>
    axios.patch('/domestic/v1/me/phone', body, {
      headers: API_CLIENT_HEADERS,
    })
  );
}

export function useTokenGoogleExchangeMutation() {
  const setLoginType = useSetAtom(loginTypeAtom);
  const userDataMutation = useUserDataMutation();
  const rudderTrack = useRudderStackTrack();

  return useMutation(
    (body: TokenExchangeFacebookPostRequest) =>
      accountApi.tokenExchangeGooglePost(body, {
        params: {
          session: true,
        },
      }),
    {
      onSuccess: async () => {
        setLoginType(LoginType.GOOGLE_LOGIN);
        try {
          const userData = await userDataMutation.mutateAsync();
          rudderTrack('login_signup_success', {
            type: 'google',
            name: 'login',
            userId: userData?.id,
          });
        } catch (e) {
          console.error(e);
        }
      },
    }
  );
}

export function useTokenFacebookExchangeMutation() {
  const setLoginType = useSetAtom(loginTypeAtom);
  const userDataMutation = useUserDataMutation();
  const rudderTrack = useRudderStackTrack();

  return useMutation(
    (body: TokenExchangeFacebookPostRequest) =>
      accountApi.tokenExchangeFacebookPost(body, {
        params: {
          session: true,
        },
      }),
    {
      onSuccess: async () => {
        setLoginType(LoginType.FACEBOOK_LOGIN);
        try {
          const userData = await userDataMutation.mutateAsync();
          rudderTrack('login_signup_success', {
            type: 'facebook',
            name: 'login',
            userId: userData?.id,
          });
        } catch (e) {
          console.error(e);
        }
      },
    }
  );
}
